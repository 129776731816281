<template>
  <div class="list">
    <ul class="list__slider">
      <li v-for="(genre, index) in genres" class="list__slider__area" :key=index>
        <button :class="favs[genre].length > 0 ? 'list__slider__area__fav--active' : 'list__slider__area__fav'"
        @click="doFavorite(favs[genre], genre)" :disabled="isProcessing || isGettingVocabularyFavs">
          <img class="list__slider__area__fav__heart" src="@/assets/img/heart-white.svg">
        </button>
        <h2 class="list__slider__area__title">{{ level }}-{{ index + 1 }}<br>{{ genre === '名詞' ? 'Danh từ' : genre === '動詞' ? 'Động từ' : 'Tính từ' }}</h2>
        <p class="list__slider__area__detail">Hãy cùng học những {{ genre === '名詞' ? 'Danh từ' : genre === '動詞' ? 'Động từ' : 'Tính từ' }} thường dùng ở trình độ {{ level }} nhé.</p>
        <button class="list__slider__area__next"
        @click="$router.push({ name: 'StudyVocabularyTest', query: { level: level, genre: genre } })">
          <img class="list__slider__area__next__arrow" src="@/assets/img/arrow_foward-white.svg">
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    // 日本語レベル
    level: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      // 単語データの取得中フラグ
      isGettingVocabularies: true,
      // お気に入り情報の取得中フラグ
      isGettingVocabularyFavs: true,
      // 単語のジャンル
      genres: ['名詞', '動詞', '形容詞'],
      // お気に入り登録中フラグ
      isProcessing: false
    }
  },
  mounted () {
    // クエリのレベルが正しいか確認
    if (!['N5', 'N4', 'N3', 'N2'].includes(this.level)) {
      window.location.href = '/notfound'
    }

    // お気に入り情報の取得
    if (this.isAcquired) {
      this.isGettingVocabularyFavs = false
    } else {
      this.addVocabularyFavs(this.uid).then(() => {
        this.isGettingVocabularyFavs = false
      })
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Boolean} お気に入り情報を取得したかどうか
     */
    isAcquired () {
      return this.getIsAcquired()()
    },
    /**
     * @return {Object} お気に入り情報
     */
    favs () {
      return this.getVocabularyFavs()(this.level)
    }
  },
  methods: {
    ...mapGetters('favVocabulary', ['getIsAcquired', 'getVocabularyFavs']),
    ...mapActions('favVocabulary', ['addVocabularyFavs']),
    ...mapActions('favVocabulary', ['doVocabularyFav']),
    /**
     * お気に入りの登録 or 解除
     * @param {String} id お気に入り情報のID
     * @param {String} genre 単語のジャンル
     */
    doFavorite (id, genre) {
      this.isProcessing = true
      this.doVocabularyFav({
        id: id,
        uid: this.uid,
        level: this.level,
        genre: genre
      }).then(() => {
        this.isProcessing = false
      })
    }

  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.list {
  width: calc(100vw - #{$main_padding});
  &__slider {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 0;
    padding-bottom: 5px;
    &::-webkit-scrollbar{
      display:none;
    }
    &__area {
      position: relative;
      display: inline-block;
      height: 216px;
      width: 216px;
      margin-right: 10px;
      background-color: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      &__fav {
        position: absolute;
        height: 28.67px;
        width: 52px;
        top: 38px;
        right: 0;
        border: none;
        background-image: url("../../../assets/img/tag.svg");
        background-repeat: no-repeat;
        &--active {
          @extend .list__slider__area__fav;
          background-image: url("../../../assets/img/tag-orange.svg");
        }
        &__heart {
          margin-top: 5px;
          margin-left: 16px;
          height: 14.4px;
          width: auto;
        }
      }
      &__title {
        margin: 0;
        margin-top: 20px;
        margin-left: 12px;
        font-size: 20px;
        font-weight: bold;
        color: #393939;
      }
      &__detail {
        width: 90%;
        margin: 0;
        margin-top: 9px;
        margin-left: 12px;
        font-size: 13px;
        white-space: normal;
        color: #A5A5A5;
      }
      &__next {
        height: 56.64px;
        width: 56.64px;
        margin-top: 4.4px;
        margin-left: 147px;
        background-color: #FFA758;
        border-radius: 50%;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        &__arrow {
          height: 25.54px;
          width: 25.54px;
          transform: scale(-1, 1);
        }
      }
    }
  }
}
</style>
