<template>
  <div class="vocabulary">
    <div class="vocabulary__explain">
      <h2 class="vocabulary__explain__title">{{ level }}</h2>
      <p class="vocabulary__explain__detail">{{ explain }}</p>
    </div>
    <list class="vocabulary__list" :level=level></list>
  </div>
</template>

<script>
import List from '@/components/study/vocabulary/list'

export default {
  components: { List },
  mounted () {
    // レベルN3, N2は一旦非表示に。将来的に条件を満たしたユーザーにのみ表示とする。
    // 該当する場合はNotFoundへ遷移させる
    if (['N3', 'N2'].includes(this.level)) this.$router.push({ name: 'NotFound' })
  },
  computed: {
    // 日本語レベル
    level () {
      return this.$route.query.level
    },
    /**
     * @return {String} 単語レベルに応じた説明
     */
    explain () {
      return this.level === 'N5' ? 'Hãy cùng học từ vựng ở trình độ N5 - tiếng Nhật cơ bản nào.'
        : this.level === 'N4' ? 'Hãy cùng học từ vựng ở trình độ N4 - tiếng Nhật cơ bản nào.'
          : this.level === 'N3' ? 'Hãy cùng học từ vựng ở trình độ N3 - tiếng Nhật thường ngày nào.'
            : this.level === 'N2' ? 'Hãy cùng học từ vựng ở trình độ N2 - tiếng Nhật trong nhiều ngữ cảnh nào.'
              : ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.vocabulary {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  &__explain {
    height: 56vh;
    width: 100vw;
    margin-left: -$main_padding;
    background-color: #FF9534;
    color: #FFFFFF;
    &__title {
      margin: 0;
      padding-top: 36px;
      padding-left: $main_padding;
      font-size: 50px;
      font-weight: bold;
    }
    &__detail {
      margin: 0;
      padding-left: $main_padding;
      width: 60vw;
      font-size: 15px;
    }
  }
  &__list {
    margin-top: -80px;
  }
}
</style>
